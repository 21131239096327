import React from 'react';
import PropTypes from 'prop-types';

import OutageCount from './outage-count';
import OutageTime from './outage-time';
import RelatedEvents from './related-events';
import moment from 'moment';

import {
  hoursAndMinutesFromSeconds,
  touchDevice,
} from 'containers/component_uptime_editor/utils';

const monthStrings = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const isTouchDevice = touchDevice();

const Tooltip = (props) => {
  if (!props.visible || !props.day || !props.day.date) return null;

  const noOutageView = (
    <div className="outages">
      <div className="no-outages-msg">No downtime recorded on this day.</div>
    </div>
  );

  const noDataView = (
    <div className="outages">
      <div className="no-data-msg">No data exists for this day.</div>
    </div>
  );

  const generateGroupOutageView = () => (
    <div className="outages">
      <OutageCount count={props.day.mc} outage_type={'major'} />
      <OutageCount count={props.day.pc} outage_type={'partial'} />
    </div>
  );

  const generateGroupNoOutageView = () => {
    /**
       We were originally using the operationalCount as part of the no outage copy for group components,
       but ultimately decided not to use it. I opted to leave the variable in place in case we ever
       decide to use it in the future.
     */
    var operationalCount = 0;
    var noDataCount = 0;
    var showcasedComponentsCount = 0;

    // Only increase operational count if showcased component has data for current day
    for (var i = 0; i < props.groupComponentData.length; i++) {
      var component = props.groupComponentData[i];

      if (!component.is_showcased) continue;

      showcasedComponentsCount++;

      if (currentDayFallsBeforeStartDate(component.start_date)) {
        noDataCount++;
      } else {
        operationalCount++;
      }
    }

    // Show no data message only if we do not have data for any showcased components in the group
    return noDataCount === showcasedComponentsCount ? noDataView : noOutageView;
  };

  const generateComponentOutageView = (majorOutage, partialOutage) => (
    <div className="outages">
      <OutageTime
        outage_type={'major'}
        hours={majorOutage.hours}
        minutes={majorOutage.minutes}
      />
      <OutageTime
        outage_type={'partial'}
        hours={partialOutage.hours}
        minutes={partialOutage.minutes}
      />
    </div>
  );

  const currentDayFallsBeforeStartDate = (startDate) => {
    const localStartDate =
      startDate && moment.parseZone(startDate).format().split('T')[0];

    return (
      startDate &&
      moment(props.day.date, 'YYYY-MM-DD').isBefore(localStartDate, 'day')
    );
  };

  let view = noOutageView;

  if (props.isGroup && (props.day.mc || props.day.pc)) {
    view = generateGroupOutageView();
  } else if (props.isGroup) {
    view = generateGroupNoOutageView();
  }
  // Regular (non-group) component
  else if (props.day.m || props.day.p) {
    const majorOutage = hoursAndMinutesFromSeconds(props.day.m);
    const partialOutage = hoursAndMinutesFromSeconds(props.day.p);
    view = generateComponentOutageView(majorOutage, partialOutage);
  }
  // Determine message for days with no outages based on component's start date
  else if (currentDayFallsBeforeStartDate(props.componentStartDate)) {
    view = noDataView;
  } else {
    view = noOutageView;
  }

  const currentDate = moment.parseZone(props.day.date);
  const formatted = currentDate.format('D MMM YYYY');

  // Calculate x box position
  let left = props.x;

  // Outer container that we are absolutely positioned against
  const containerWrapper = document.querySelector('.history-container');
  const containerBounds = containerWrapper.getBoundingClientRect();

  // Padding for each side
  const padding = (window.innerWidth - containerBounds.width) / 2.0;

  const containerWidth = 325;

  // If the left side + padding of frame is outside window
  if (left > window.innerWidth - (containerWidth + padding)) {
    // Move it to the left plus padding to align with right side of screen
    left = window.innerWidth - (containerWidth + padding);
  } else if (left < -padding) {
    left = -padding;
  }

  // Defaults to half of width of box - half width of arrow box
  let arrowLeft = 155.5;
  // If we adjusted the left side of the tooltip, adjust the arrow box by the differnce
  // we calculated for the tooltip movement
  if (left !== props.x) {
    arrowLeft -= left - props.x;
  }

  return (
    <div
      id="uptime-tooltip"
      onMouseEnter={props.tooltipEntered}
      onMouseLeave={props.tooltipLeft}
      style={{ display: 'block', left: left, top: props.y }}
    >
      <div id="box-arrow" style={{ left: arrowLeft }} />
      <div className="tooltip-box">
        <div className="tooltip-content">
          <div className={`tooltip-close${isTouchDevice ? '' : ' hidden'}`}>
            <i className="fa fa-times" onClick={props.closeTooltip}></i>
          </div>
          <p className="date">{formatted}</p>
          {view}
          <RelatedEvents
            events={props.day.events}
            isGroup={props.isGroup}
            totalOutage={props.day.m + props.day.p}
          />
        </div>
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  closeTooltip: PropTypes.func,
  day: PropTypes.object,
  isGroup: PropTypes.bool,
  tooltipEntered: PropTypes.func,
  tooltipLeft: PropTypes.func,
  visible: PropTypes.bool,
  x: PropTypes.number,
  y: PropTypes.number,
  componentStartDate: PropTypes.string,
  groupComponentData: PropTypes.arrayOf(PropTypes.object),
};
export default Tooltip;
