import React from 'react';
import PropTypes from 'prop-types';
import inflection from 'inflection';

const OutageCount = (props) => {
  if (props.count === 0) return null;

  const id = `${props.outage_type}-outage-group-count`;
  const spanClass = `${props.outage_type}_outage`;
  return (
    <div id={id} className="outage-count">
      <i className={`component-status page-colors text-color ${spanClass}`}></i>
      <span className="count">
        {props.count} {inflection.inflect('component', props.count)}
      </span>
      &nbsp;had a {props.outage_type} outage.
    </div>
  );
};

OutageCount.propTypes = {
  count: PropTypes.number,
  outage_type: PropTypes.string,
};

export default OutageCount;
