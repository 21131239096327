import React from 'react';
import PropTypes from 'prop-types';

const RelatedEvents = props => {
  // If it is a group, dont show events
  // If events is somehow missing, return null
  // If there is neither total outage or events, return null
  if (
    props.isGroup ||
    !props.events ||
    (props.totalOutage === 0 && props.events.length === 0)
  )
    return null;

  // If there is outage, but no events, show no events message
  if (props.totalOutage > 0 && props.events.length === 0)
    return (
      <div className="no-related-msg">
        <p>No incidents or maintenance related to this downtime.</p>
      </div>
    );

  const trimName = name => {
    if (name.length <= 90) return name;

    return name.substring(0, 90) + '...';
  };

  return (
    <div className="related-events">
      <h3 id="related-event-header">Related</h3>
      <ul id="related-events-list">
        {props.events.map(e => (
          <li className="related-event" key={e.code}>
            <a
              className="related-event-link"
              href={window.Routes.incident_path(e.code)}
              target="_blank"
            >
              {trimName(e.name)}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RelatedEvents;
