import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Incident from './incident';

const IncidentHistory = ({ incidents }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleIncidents = () => {
    setExpanded(!expanded);
  };

  const handleToggleKey = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      toggleIncidents();
    }
  };

  const buttonText = () => {
    if (expanded) {
      return <span>- Collapse Incidents</span>;
    }
    return (
      <span>
        <var data-var="show-all">+ Show All</var>
        <var data-var="num-incidents">{` ${incidents.length} `}</var>
        <var data-var="incidents">Incidents</var>
      </span>
    );
  };

  const displayToggler = () => {
    return (
      <div
        aria-expanded={expanded}
        aria-controls="incidents1"
        className="expand-incidents font-small border-color color-secondary custom-focus"
        onClick={toggleIncidents}
        onKeyDown={handleToggleKey}
        role="button"
        tabIndex={0}
        air
      >
        {buttonText()}
      </div>
    );
  };

  const renderIncidents = () => {
    if (expanded) {
      return incidents.map((incident) => (
        <Incident key={incident.code} incident={incident} />
      ));
    }
    return incidents
      .slice(0, 3)
      .map((incident) => <Incident key={incident.code} incident={incident} />);
  };

  return (
    <div className="incident-history">
      <div className="incident-list" id="incidents1">
        {incidents.length === 0 ? (
          <span className="small">No incidents reported for this month.</span>
        ) : (
          renderIncidents()
        )}
      </div>
      {incidents.length > 3 ? displayToggler() : null}
    </div>
  );
};

IncidentHistory.propTypes = {
  incidents: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default IncidentHistory;
