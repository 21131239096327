import React from 'react';
import PropTypes from 'prop-types';

const OutageTime = props => {
  if (props.hours === 0 && props.minutes === 0) return null;

  const iconClass = `${props.outage_type}_outage`;
  const outage = `${props.outage_type[0].toUpperCase() +
    props.outage_type.slice(1)} outage`;
  return (
    <div className={`outage-field ${props.outage_type}`}>
      <span className="label">
        <i
          className={`component-status page-colors text-color ${iconClass}`}
        ></i>
        {outage}
      </span>
      &nbsp;
      <span className="value-hrs">{props.hours} hrs</span>
      &nbsp;
      <span className="value-mins">{props.minutes} mins</span>
    </div>
  );
};

OutageTime.propTypes = {
  outage_type: PropTypes.string,
  hours: PropTypes.number,
  minutes: PropTypes.number,
};

export default OutageTime;
