import 'common/styles/markdown.scss';

/**
 * Components related to Availability Showcase
 */

import componentLoader from 'utils/component_loader';
import PageColors from 'page_colors';
import HistoryIndex from 'components/status/history_index';
import UptimeCalendar from 'components/status/uptime_calendar';
import CookieConsentBanner from 'components/status/cookie-consent-banner';
import { setBooleanFeatureFlagResolver } from '@atlaskit/platform-feature-flags';

setBooleanFeatureFlagResolver((flagKey) => {
  if (flagKey == 'platform.moonjelly.browser-storage-controls') {
    return true;
  }
  return false;
});

window.pageColors = new PageColors(window.pageColorData);

componentLoader({
  HistoryIndex,
  UptimeCalendar,
  CookieConsentBanner,
});
