export default class Querier {
  // Decide if a value is present
  static isPresent(value) {
    if (value === undefined) {
      return false;
    }
    if (value === null) {
      return false;
    }
    if (value === '') {
      return false;
    }
    return true;
  }

  // Convert a query string into an object
  // eg. "foo=bar&baz=qux" --> {foo: 'bar', baz: 'qux'}
  static parse(str) {
    const params = {};
    const query = str.split('&');
    query.forEach(pair => {
      const kv = pair.split('=');
      if (kv[1]) {
        params[kv[0]] = decodeURIComponent(kv[1]);
      }
    });
    return params;
  }

  // Given an object, generate a query string
  // eg. {foo: 'bar', baz: 'qux'} --> "foo=bar&baz=qux"
  static generate(data) {
    const parts = [];
    for (const key in data) {
      if (this.isPresent(data[key])) {
        parts.push(`${key}=${data[key]}`);
      }
    }
    return parts.join('&');
  }

  // Get the current query string as an object
  static get() {
    return this.parse(location.search.substring(1));
  }

  // Set the current query string from an object
  // If "merge" option is true, merge the new params over any existing params
  // If "reload" option is true, do this via location change rather than pushState
  // If "path" option is set, change the path accordingly
  static set(data, { merge = false, reload = false, path = null } = {}) {
    if (merge) {
      const current = this.get();
      for (const key in current) {
        if (!(key in data)) {
          data[key] = current[key];
        }
      }
    }

    const qs = this.generate(data);
    path = path || location.pathname;
    if (qs.length > 0) {
      path += `?${qs}`;
    }

    if (reload) {
      window.location = path;
    } else {
      history.pushState({}, document.title, path);
    }
    return path;
  }

  // Update the query string via #set, and send an ajax request to get the resulting
  // new URL. Returns a promise from the ajax call.
  static fetch(data = this.get(), setOpts = {}) {
    const path = this.set(data, setOpts);

    return fetch(path, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'same-origin',
    }).then(response => response.json());
  }
}
