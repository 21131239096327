import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Querier from 'querier';

/*
 * A shared paginator component for incidents and uptime history.
 *
 * @param {func} responseHandler = callback function for the pagination action
 * @param {string} startDate = label for the paginator start date
 * @param {string} endDate = label for the paginator end date
 */
class HistoryPaginator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: parseInt(Querier.get().page || 1, 10),
    };
    this._pageForward = this._pageForward.bind(this);
    this._pageBack = this._pageBack.bind(this);
    this._responseHandler = props.responseHandler;
  }

  _canPageBack() {
    return this.state.pageNumber < 40;
  }

  _canPageForward() {
    return this.state.pageNumber > 1;
  }

  _pageBack(event) {
    event.preventDefault();
    if (this._canPageBack()) {
      this.state.pageNumber += 1;
      Querier.fetch({ page: this.state.pageNumber }, { merge: true }).then(
        (responseData) => {
          this._responseHandler(responseData);
        },
      );
    }
  }

  _pageForward(event) {
    event.preventDefault();
    if (this._canPageForward()) {
      this.state.pageNumber -= 1;
      Querier.fetch({ page: this.state.pageNumber }, { merge: true }).then(
        (responseData) => {
          this._responseHandler(responseData);
        },
      );
    }
  }

  render() {
    const backClass = cx('previous-page border-color color-secondary', {
      disabled: !this._canPageBack(),
    });
    const fwdClass = cx('next-page border-color color-secondary', {
      disabled: !this._canPageForward(),
    });

    return (
      <div className="pagination-container">
        <div className="pagination">
          <a
            href="#back"
            className={backClass}
            onClick={this._pageBack}
            aria-label="Previous page"
          >
            <i className="left-arrow" />
          </a>
          <span className="current">
            <span>{this.props.startDateMonth}</span>
            <var data-var="start-date-year">
              {' '}
              {this.props.startDateYear}
            </var> to <span>{this.props.endDateMonth}</span>
            <var data-var="end-date-year"> {this.props.endDateYear}</var>
          </span>
          <a
            href="#forward"
            className={fwdClass}
            onClick={this._pageForward}
            aria-label="Next page"
          >
            <i className="right-arrow" />
          </a>
        </div>
      </div>
    );
  }
}

HistoryPaginator.propTypes = {
  startDateMonth: PropTypes.string.isRequired,
  startDateYear: PropTypes.number.isRequired,
  endDateMonth: PropTypes.string.isRequired,
  endDateYear: PropTypes.number.isRequired,
  responseHandler: PropTypes.func.isRequired,
};

export default HistoryPaginator;
