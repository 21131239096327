import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SelectInput from 'components/select_input';
import CalendarDay from './calendar_day';
import Querier from 'querier';
import HistoryPaginator from './history_paginator';
import Tooltip from './tooltip/tooltip.jsx';

import { touchDevice } from 'containers/component_uptime_editor/utils';

let enterTimeoutId;
let leaveTimeoutId;

const Month = (props) => {
  const uptimePercentageFormatter = (uptimePercentage) => {
    // uptimePercentage being null indicates we have no data on uptime at all for this month therefore don't display a percentage
    if (uptimePercentage) {
      return `${
        // This fun thing takes the float to a string and trims the first two decimals. Necessary to avoid rounding 99.9999 up to 100.00.
        (uptimePercentage * 100.0).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
      }%`;
    }

    return '';
  };

  const uptimePercentage = uptimePercentageFormatter(
    props.month.uptime_percentage,
  );

  return (
    <div className="calendar-month">
      <div className="month-header">
        <h6 className="month-name">
          {props.month.name} <var data-var="year">{props.month.year}</var>
        </h6>
        <small className="month-uptime">{uptimePercentage}</small>
      </div>
      <Calendar
        hoverDay={props.hoverDay}
        leaveDay={props.leaveDay}
        month={props.month}
        renderUptimePatternFF={props.renderUptimePatternFF}
      />
    </div>
  );
};

const Calendar = (props) => {
  const leftPadding = Array(props.month.start_offset)
    .fill()
    .map((_, i) => {
      return <CalendarDay hoverDay={() => {}} hidden key={`${i}-padding`} />;
    });

  const days = props.month.days.map((day, i) => {
    return (
      <CalendarDay
        day={day}
        color={day.color}
        hoverDay={props.hoverDay}
        leaveDay={props.leaveDay}
        key={i}
        tooltip={day.tooltip}
        renderUptimePatternFF={props.renderUptimePatternFF}
      />
    );
  });

  return <div className="days">{leftPadding.concat(days)}</div>;
};

class UptimeHistory extends Component {
  /*
   * Top level wrapper for the uptime calendar, takes an array of showcased components,
   * and an object of month data for display.
   *
   * @param {object} props
   * @param {object} components_select_list = array of component name/value for the select input
   * @param {object} months = object representing a month that contains a day count, starts_on day, and the component uptime for that month
   */

  constructor(props) {
    super(props);

    this.state = {
      component_select_list: props.component_select_list,
      component: props.component,
      displayTooltip: false,
      isGroup: props.is_group,
      months: props.months,
      group_component_data: props.group_component_data,
      renderUptimePatternFF: props.renderUptimePatternFF,
    };

    this._changeComponent = this._changeComponent.bind(this);
    this._handlePagination = this._handlePagination.bind(this);
  }

  _drawMonths() {
    const { months, renderUptimePatternFF } = this.state;
    return months.map((month) => (
      <Month
        hoverDay={this._hoverDay}
        key={month.name}
        leaveDay={this._leaveDay}
        onFocus={this._onFocus}
        onBlur={this._onBlur}
        month={month}
        renderUptimePatternFF={renderUptimePatternFF}
      />
    ));
  }

  _changeComponent(event) {
    const componentCode = event.target.value;
    const path = `/uptime/${componentCode}`;
    Querier.fetch({}, { path, merge: true }).then((data) => {
      // Necessary to clear activeDay and visible to prevent tooltip flashing
      // and inaccurate data
      this.setState({
        activeDay: null,
        component_select_list: data.component_select_list,
        component: data.component,
        isGroup: data.is_group,
        months: data.months,
        visible: false,
        group_component_data: data.group_component_data,
        renderUptimePatternFF: data.renderUptimePatternFF,
      });
    });
  }

  _handlePagination(data) {
    this.setState({
      component_select_list: data.component_select_list,
      component: data.component,
      months: data.months,
    });
  }

  _hoverDay = (x, y, day, target) => {
    // Necessary as the mouse pans between blocks timeout will be set
    clearTimeout(leaveTimeoutId);

    // Necessary, as if we are panning quickly we dont want to open the tooltip
    clearTimeout(enterTimeoutId);

    enterTimeoutId = setTimeout(() => {
      this.setState({
        activeDay: day,
        displayTooltip: true,
        touchTarget: target,
        x: x,
        y: y,
      });
    }, 150);
  };

  _leaveDay = () => {
    // Necessary for clearing timeout when we quickly pan over elements
    clearTimeout(enterTimeoutId);

    leaveTimeoutId = setTimeout(() => {
      this.setState({
        displayTooltip: false,
      });
    }, 250);
  };

  _onFocus = (day, target) => {
    this._hoverDay(0, 0, day, target);
  };

  _onBlur = () => {
    this._leaveDay();
  };

  _closeTooltip = () => {
    this.setState({
      displayTooltip: false,
    });
  };

  _tooltipEntered() {
    // Prevent tooltip from closing when exiting block and entering tooltip
    clearTimeout(leaveTimeoutId);
  }

  render() {
    const startDateMonth = this.state.months[0].name;
    const startDateYear = this.state.months[0].year;
    const endDateMonth = this.state.months[2].name;
    const endDateYear = this.state.months[2].year;

    return (
      <div className="uptime-calendar">
        <div className="uptime-header">
          <div className="component-selector">
            <SelectInput
              name="component"
              ariaLabel="Select Component"
              defaultValue={this.state.component.id}
              options={this.state.component_select_list}
              onChange={this._changeComponent}
            />
          </div>
          <HistoryPaginator
            startDateMonth={startDateMonth}
            startDateYear={startDateYear}
            endDateMonth={endDateMonth}
            endDateYear={endDateYear}
            responseHandler={this._handlePagination}
          />
        </div>
        <div className="uptime-calendar-display">
          {this._drawMonths()}
          <Tooltip
            closeTooltip={this._closeTooltip}
            day={this.state.activeDay}
            isGroup={this.state.isGroup}
            tooltipEntered={this._tooltipEntered}
            tooltipLeft={this._leaveDay}
            visible={this.state.displayTooltip}
            x={this.state.x}
            y={this.state.y}
            componentStartDate={this.state.component.start_date}
            groupComponentData={this.state.group_component_data}
          />
        </div>
      </div>
    );
  }
}

Month.propTypes = {
  hoverDay: PropTypes.func.isRequired,
  leaveDay: PropTypes.func.isRequired,
  month: PropTypes.object.isRequired,
  renderUptimePatternFF: PropTypes.bool,
};

Calendar.propTypes = {
  hoverDay: PropTypes.func.isRequired,
  leaveDay: PropTypes.func.isRequired,
  month: PropTypes.object.isRequired,
  renderUptimePatternFF: PropTypes.bool,
};

UptimeHistory.propTypes = {
  component_select_list: PropTypes.arrayOf(PropTypes.object).isRequired,
  component: PropTypes.object.isRequired,
  is_group: PropTypes.bool.isRequired,
  months: PropTypes.arrayOf(PropTypes.object).isRequired,
  group_component_data: PropTypes.arrayOf(PropTypes.object),
  renderUptimePatternFF: PropTypes.bool,
};

export default UptimeHistory;
