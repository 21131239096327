/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const FUTURE_DAY_COLOR = '#EAEAEA';

class CalendarDay extends Component {
  _displayTooltip = (e) => {
    // Since container is position: relative, the tooltip needs
    // to be calculated based on the position inside the element +
    // the padding from the outside of container
    const containerWrapper = document.querySelector('.history-container');
    const containerBounds = containerWrapper.getBoundingClientRect();

    // Half width of tooltip container
    const halfContainerWidth = 162.5;

    if (e.cancelable) {
      e.preventDefault();
    }

    const { target } = e;
    const rect = e.target.getBoundingClientRect();
    // Center of the box, minus half the width of tooltip
    const left =
      rect.left - containerBounds.left + rect.width / 2.0 - halfContainerWidth;

    // Offset it slightly from the bottom of the box
    const bottom = rect.bottom - containerBounds.top + 9;
    this.props.hoverDay(left, bottom, this.props.day, target);
  };

  render() {
    const { day, color, hidden, leaveDay, renderUptimePatternFF } = this.props;
    const activeDayClass =
      !hidden && color !== FUTURE_DAY_COLOR ? ' active' : '';

    if (renderUptimePatternFF) {
      return (
        <svg
          className={`day${activeDayClass}`}
          width="32"
          height="32"
          xmlns="http://www.w3.org/2000/svg"
          ref={(el) => (this.svg = el)}
          onMouseEnter={this._displayTooltip}
          onTouchEnd={this._displayTooltip}
          onMouseLeave={leaveDay}
          onFocus={this._displayTooltip}
          onBlur={leaveDay}
          tabIndex="0"
          aria-labelledby="uptime-tooltip"
        >
          {day?.m && day.m !== 0 ? (
            <>
              <rect width="32" height="32" fill={color} />
              <g opacity="0.5" clipPath="url(#clip0_639_556)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 17.8855V14.1142L14.1142 0H17.8855L0 17.8855ZM0 25.8855V22.1142L22.1142 0H25.8855L0 25.8855ZM1.88548 32H0V30.1142L30.1142 0H32V1.88547L1.88548 32ZM9.88547 32H6.11424L32 6.11423V9.88547L9.88547 32ZM17.8855 32H14.1142L32 14.1142V17.8855L17.8855 32ZM25.8855 32H22.1142L25.1592 28.955L25.1716 28.9425L28.9429 25.1712L28.9555 25.1588L32 22.1142V25.8855L25.8855 32ZM32 30.1142V32H30.1142L32 30.1142ZM9.88547 0L0 9.88547V6.11424L6.11424 0H9.88547ZM0 1.88564V0H1.88564L0 1.88564Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_639_556">
                  <rect
                    className={hidden ? 'hide' : null}
                    width="32"
                    height="32"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </>
          ) : day?.p && day.p !== 0 ? (
            <>
              <rect width="32" height="32" fill={color} />
              <g opacity="0.5">
                <path d="M32 1.33203H0V3.9987H32V1.33203Z" fill="white" />
                <path d="M32 6.66536H0V9.33203H32V6.66536Z" fill="white" />
                <path d="M0 11.9987H32V14.6654H0V11.9987Z" fill="white" />
                <path d="M32 17.332H0V19.9987H32V17.332Z" fill="white" />
                <path d="M0 22.6654H32V25.332H0V22.6654Z" fill="white" />
                <path d="M32 27.9987H0V30.6654H32V27.9987Z" fill="white" />
              </g>
            </>
          ) : (
            <rect
              className={hidden ? 'hide' : null}
              width="32"
              height="32"
              fill={color}
            />
          )}
        </svg>
      );
    }

    return (
      <svg
        className={`day${activeDayClass}`}
        width="32"
        height="32"
        xmlns="http://www.w3.org/2000/svg"
        ref={(el) => (this.svg = el)}
        onMouseEnter={this._displayTooltip}
        onTouchEnd={this._displayTooltip}
        onMouseLeave={leaveDay}
        onFocus={this._displayTooltip}
        onBlur={leaveDay}
        tabIndex="0"
        aria-labelledby="uptime-tooltip"
      >
        <rect
          className={hidden ? 'hide' : null}
          width="32"
          height="32"
          fill={color}
        />
      </svg>
    );
  }
}

CalendarDay.defaultProps = {
  color: FUTURE_DAY_COLOR,
};

CalendarDay.propTypes = {
  day: PropTypes.object,
  color: PropTypes.string,
  hidden: PropTypes.bool,
  renderUptimePatternFF: PropTypes.bool,
  hoverDay: PropTypes.func,
  leaveDay: PropTypes.func,
};

export default CalendarDay;
