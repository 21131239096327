import React from 'react';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';

class Incident extends React.Component {
  _renderIncidentMessage() {
    return { __html: sanitizeHtml(this.props.incident.message) };
  }

  _renderIncidentTimestamp() {
    return { __html: this.props.incident.timestamp };
  }

  render() {
    let linkClasses = `impact-${this.props.incident.impact} incident-title font-large`;
    let path = Routes.incident_url(this.props.incident.code);

    return (
      <div className="incident-data incident-container">
        <a href={path} className={linkClasses}>
          {this.props.incident.name}
        </a>
        <div
          className="message incident-body color-primary"
          dangerouslySetInnerHTML={this._renderIncidentMessage()}
        />
        {/* Timestamp is built by IncidentHelper#incident_historical_time_range, not using dangerouslySetInnerHTML requires */}
        {/* lots of logic change in passing in many parameters. Will come back to this if find a better way. */}
        <div
          className="secondary font-small color-secondary"
          dangerouslySetInnerHTML={this._renderIncidentTimestamp()}
        />
      </div>
    );
  }
}

Incident.propTypes = {
  incident: PropTypes.object.isRequired,
};

export default Incident;
